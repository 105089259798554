<template>
    <div class="state-display-container">
        <div v-if="loading">
            <v-progress-circular size="60" indeterminate />
            <h3 v-if="loadingMessage">{{ loadingMessage }}</h3>
        </div>
        <div v-else-if="error">
            <v-icon size="60" color="error">mdi-alert-circle</v-icon>
            <h3 v-if="errorMessage">{{ errorMessage }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StateDisplay',
    data() {
        return {}
    },
    props: {
        loading: {
            required: false,
            type: Boolean,
        },
        error: {
            required: false,
            type: Boolean,
        },
        loadingMessage: {
            required: false,
            type: String,
        },
        errorMessage: {
            required: false,
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
.state-display-container {
    display: flex;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            padding-top: 12px;
            text-align: center;
        }
    }
}
</style>
