<template>
    <v-app>
        <MainMenu class="main-menu" />
        <v-main>
            <div class="background-curve">
                <div class="curve"></div>
                <div class="content">
                    <router-view />
                </div>
            </div>
        </v-main>
        <MainFooter class="main-footer" />
        <AppSnackbars />
    </v-app>
</template>

<script>
import AppSnackbars from '@/components/AppSnackbars'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import RegisterNewsletter from '@/components/Authentication/CreateAccount/CreateAccount'
import RegisterView from '@/views/CreateAccountView'
import AnimationLoop from '@/components/AnimationLoop'
import { getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { app } from '../firebase'
import LoginView from '@/views/LoginView'
import MainMenu from '@/components/MainMenu'
import MainFooter from '@/components/MainFooter'

export default {
    name: 'App',
    components: {
        MainFooter,
        MainMenu,
        LoginView,
        AnimationLoop,
        RegisterView,
        RegisterNewsletter,
        AppSnackbars,
    },
    computed: {
        ...mapGetters(['key', 'currentUser']),
    },
    mounted() {
        const auth = getAuth(app)
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await this.listenForUserChanges()
            } else {
                this.setAppData(null)
            }
        })

        onIdTokenChanged(auth, async (data) => {
            if (data?.auth?.currentUser?.uid) {
                this.ready = true
            }
        })
    },
    destroyed() {
        this.unsubscribeAppData()
    },
    data: () => ({}),
    methods: {
        ...mapActions(['listenForUserChanges', 'unsubscribeAppData']),
        ...mapMutations(['setAppData']),
    },
}
</script>

<style lang="scss" scoped>
.v-main {
    z-index: 2;
}

.main-footer {
    z-index: 1;
}

.background-curve {
    position: relative;
}

.curve {
    position: fixed;
    top: 50vh;
    left: -50px;
    width: calc(100vw + 100px);
    height: calc(50vh + 200px);
    background: linear-gradient(-45deg, #ff416c, #ff4b2b);
    border-radius: 50% 50% 0 0 / 20% 20% 0 0; /* adjust the values to create a wave shape */
    transform: translateY(-50%);
    animation: move 20s ease-in-out infinite;
    overflow: hidden;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(85%, 60%);
    background-color: hsl(216, 21%, 16%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #3c31dd;
    transform: translate(-4%, 40%);
    z-index: -1;
}

.content {
    position: relative;
    z-index: 1;
}

@keyframes move {
    0% {
        transform: translateX(-50px) translateY(-20px);
    }
    50% {
        transform: translateX(50px) translateY(20px);
    }
    100% {
        transform: translateX(-50px) translateY(-20px);
    }
}
</style>
