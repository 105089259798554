<template>
    <v-container class="print-view-container">
        <MainHeader class="main-header" />
        <PrintGenerator :id="$route.params.id" class="mb-12" />
    </v-container>
</template>
<script>
import PrintGenerator from '@/components/PrintGenerator/PrintGenerator'
import MainHeader from '@/components/MainHeader'
export default {
    name: 'PrintView',
    components: { PrintGenerator, MainHeader },
}
</script>

<style lang="scss" scoped>
.print-view-container {
    max-width: 1100px;
}
</style>
