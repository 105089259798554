export const allFonts = [
    { value: 'Amatic SC', label: 'Schrift 1' },
    { value: 'Yellowtail', label: 'Schrift 2' },
    { value: 'Dancing Script', label: 'Schrift 3' },
    { value: 'Satisfy', label: 'Schrift 4' },
    { value: 'Raleway', label: 'Schrift 5' },
    { value: 'Rubik Iso', label: 'Schrift 6' },
]

export const allPaymentMethods = [
    { alt: 'Visa', img: require('@/assets/images/paymentMethods/visa.png') },
    {
        alt: 'Klarna',
        img: require('@/assets/images/paymentMethods/klarna.png'),
    },
    {
        alt: 'Google Pay',
        img: require('@/assets/images/paymentMethods/google.png'),
    },
    {
        alt: 'Apple Pay',
        img: require('@/assets/images/paymentMethods/apple.png'),
    },
]
