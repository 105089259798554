import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'
import PrintView from '@/views/PrintView'
import ResultView from '@/views/ResultView'
import CreateAccountView from '@/views/CreateAccountView'
import FinalizeAccountCreationView from '@/views/FinalizeAccountCreationView'
import LoginView from '@/views/LoginView'
import { requireAuth } from './guards'
import TermsAndConditions from '@/views/TermsAndConditions'
import SandboxView from '@/views/SandboxView'
import OrderView from '@/views/OrderView'
import OrderResults from '@/components/OrderResult/OrderResults'
import OrderSuccess from '@/components/OrderResult/OrderSuccess'

Vue.use(VueRouter)

const routes = [
    {
        path: '/sand',
        name: 'sandbox',
        component: SandboxView,
    },
    {
        path: '/order/:id',
        name: 'order',
        component: OrderView,
        beforeEnter: requireAuth,
        children: [
            {
                path: '/',
                name: 'order-results',
                component: OrderResults,
                beforeEnter: requireAuth,
            },
            {
                path: 'success',
                name: 'order-success',
                component: OrderSuccess,
                beforeEnter: requireAuth,
            },
        ],
    },
    {
        path: '/',
        name: 'home',
        component: MainView,
        beforeEnter: requireAuth,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/print/:id',
        name: 'print',
        component: PrintView,
        beforeEnter: requireAuth,
    },
    {
        path: '/result/:id',
        name: 'result',
        component: ResultView,
        beforeEnter: requireAuth,
    },
    {
        path: '/terms',
        name: 'terms',
        component: TermsAndConditions,
    },
    {
        path: '/register',
        name: 'register',
        component: CreateAccountView,
    },
    {
        path: '/register/:key',
        name: 'register',
        component: CreateAccountView,
    },
    {
        path: '/finalizeRegistration/:registrationKey',
        name: 'finalizeRegistration',
        component: FinalizeAccountCreationView,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
