import { render, staticRenderFns } from "./PrintView.vue?vue&type=template&id=231a4f01&scoped=true&"
import script from "./PrintView.vue?vue&type=script&lang=js&"
export * from "./PrintView.vue?vue&type=script&lang=js&"
import style0 from "./PrintView.vue?vue&type=style&index=0&id=231a4f01&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231a4f01",
  null
  
)

export default component.exports