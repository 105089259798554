<template>
    <v-container>
        <div class="register-container">
            <FinalizeAccountCreation :registration-key="registrationKey" />
        </div>
    </v-container>
</template>

<script>
import CreateAccount from '@/components/Authentication/CreateAccount/CreateAccount'
import FinalizeAccountCreation from '@/components/Authentication/CreateAccount/FinalizeAccountCreation'
export default {
    name: 'RegisterView',
    components: { FinalizeAccountCreation, CreateAccount },
    data() {
        return {
            registrationKey: this.$route.params.registrationKey,
        }
    },
}
</script>

<style lang="scss" scoped>
.register-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
