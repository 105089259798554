<template>
    <div style="width: 800px">
        <ImageMockup
            :mockup-img-src="
                require('@/assets/images/sandbox/poster_mockup.png')
            "
            :product-image-corners="[
                [0.3687368737, 0.0888088809],
                [0.750975, 0.0657065707],
                [0.7557755776, 0.7356735674],
                [0.3717371737, 0.7248735374],
            ]"
        >
            <PrintPreview :use-aspect-ratio="false" />
        </ImageMockup>
    </div>
</template>

<script>
import ImageMockup from '@/components/OrderResult/MockupImageWrapper'
import PrintPreview from '@/components/PrintGenerator/components/PrintPreview'
export default {
    name: 'SandboxView',
    components: { PrintPreview, ImageMockup },
}
</script>

<style scoped></style>
