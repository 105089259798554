import { getAnalytics, logEvent } from 'firebase/analytics'
import { app } from '../../firebase'

const analytics = getAnalytics(app)

export const analyticEvents = {
    SELECT_IMAGE: 'select_image', // User selected an image from the filseystem
    DRAW_IMAGE: 'draw_image', // User clicks on Zeichne mein Liebling
    OPEN_EDITOR: 'open_editor', // User got the drawing and continue to editor to create the print template
    EDITOR_SETTING_CHANGE: 'editor_setting_change', // All changes made in the editor are triggered here, it is used to get a better understanding which features are useful/useless
    EDITOR_TEMPLATE: 'editor_template',
    EDITOR_CROP_IMAGE: 'editor_clipped_image',
    EDITOR_REMOVE_BACKGROUND: 'editor_remove_background',
    EDITOR_IMAGE_BACKGROUND: 'editor_image_background',
    EDITOR_TEXT: 'editor_text',
    EDITOR_TEXT_FONT: 'editor_text_font',
    EDITOR_TEXT_COLOR: 'editor_text_color',
    EDITOR_TEXT_SIZE: 'editor_text_size',
    OPEN_ORDER: 'open_order', // User got the finalized template and order/ downloads the result
    ORDER_SETTING_CHANGE: 'order_setting_change', // All Changes on order Page results in a trigger
    START_DOWNLOAD: 'start_download', // User starts to download a template
    END_DOWNLOAD: 'end_download', // User has waited and the download is completed
    START_CHECKOUT_PAYPAL: 'start_checkout_paypal', // User clicks Paypal Checkout
    END_CHECKOUT_PAYPAL: 'end_checkout_paypal', // User was redirected to Paypal Checkout
    START_CHECKOUT_STRIPE: 'start_checkout_stripe', // User clicks Stripe Checkout
    END_CHECKOUT_STRIPE: 'end_checkout_stripe', // User was redirected to Stripe Checkout
}

export const triggerEvent = (eventName, data) => {
    // console.log(eventName, data)
    logEvent(analytics, eventName, data || {})
}
