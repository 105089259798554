import { getAuth } from 'firebase/auth'

const requireAuth = async (to, from, next) => {
    const auth = getAuth()
    const user = auth.currentUser
    if (!!user) {
        console.log('hi')
        // user is authenticated, allow access to the route
        next()
    } else {
        console.log(user)
        // user is not authenticated, redirect to login page
        next({
            path: '/login',
            query: { redirect: to.fullPath },
        })
    }
}

export default requireAuth
