<template>
    <div class="cic">
        <div v-if="!loading && !disabled" class="cic__animation-container">
            <AnimationLoop
                :key="currentTip"
                v-if="
                    ((currentTip && isEditMode) || currentTip === 'click') &&
                    showAllTips
                "
                height="130px"
                size="130px"
                :animation-name="currentTip"
                :delay-millis="1200"
            />
        </div>
        <v-hover v-if="!isEditMode" v-slot="{ hover }" :disabled="disabled">
            <div class="cic__image-container">
                <v-img
                    :src="image"
                    height="100%"
                    class="cic__image-container"
                />
                <v-btn
                    v-if="!isEditMode"
                    x-large
                    class="cic__edit-button"
                    :class="{ 'cic__edit-button--active': hover }"
                    fab
                    dark
                    @click="editImage"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>
        </v-hover>
        <vue-cropper
            v-if="isEditMode"
            :style="`visibility: ${loading ? 'hidden' : 'visible'}`"
            ref="cropper"
            class="cic__image-container"
            :guides="true"
            :background="false"
            :view-mode="viewMode"
            drag-mode="move"
            :src="originalImage"
            alt="Image not available"
            @ready="initCropper"
            @zoom="() => handleTips('zoom')"
            @cropmove="() => handleTips('move')"
        >
        </vue-cropper>
        <v-progress-circular
            v-if="loading && isEditMode"
            class="cic__loading"
            indeterminate
            size="30"
        />

        <v-btn
            v-if="isEditMode && !loading"
            @click="resetCrop"
            small
            color="error"
            fab
            dark
            class="cic__cancel-btn"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
            v-if="isEditMode && !loading"
            @click="crop"
            small
            color="success"
            fab
            dark
            class="cic__save-btn"
        >
            <v-icon>mdi-check-bold</v-icon>
        </v-btn>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import AnimationLoop from '@/components/AnimationLoop'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'CroppableImageConatiner',
    data() {
        return {
            loading: false,
            isEditMode: false,
            showAllTips: false,
            currentTip: 'click',
        }
    },
    components: {
        AnimationLoop,
        VueCropper,
    },
    props: {
        image: {
            required: true,
            type: String,
        },
        originalImage: {
            required: true,
            type: String,
        },
        width: {
            default: '100%',
        },
        height: {
            default: '100%',
        },
        viewMode: {
            default: 3,
        },
        disabled: {
            default: false,
        },
    },
    computed: {
        ...mapGetters(['showTips']),
    },
    created() {
        this.showAllTips = this.showTips
    },
    methods: {
        ...mapMutations(['setShowTips']),
        editImage() {
            this.isEditMode = true
            this.handleTips('click')
            this.loading = true
        },
        async initCropper() {
            this.setCropperDimensions()
            this.loading = false
        },
        setCropperDimensions() {
            const canvasData = this.$refs.cropper.getCanvasData()
            this.$refs.cropper.setCropBoxData({
                left: 0,
                top: 0,
                width: canvasData.naturalWidth,
                height: canvasData.naturalHeight,
            })
        },
        crop() {
            const canvas = this.$refs.cropper.getCanvasData()

            this.$emit('onCrop', {
                dataUrl: this.$refs.cropper
                    .getCroppedCanvas()
                    .toDataURL(this.imageFileType),
                values: {
                    relative_x:
                        this.$refs.cropper.getData().x / canvas.naturalWidth,
                    relative_y:
                        this.$refs.cropper.getData().y / canvas.naturalHeight,
                    relative_width:
                        this.$refs.cropper.getData().width /
                        canvas.naturalWidth,
                    relative_height:
                        this.$refs.cropper.getData().height /
                        canvas.naturalHeight,
                },
            })
            this.isEditMode = false
        },
        resetCrop() {
            this.$emit('onCropReset')
            this.isEditMode = false
        },
        handleTips(tipName) {
            this.setShowTips(false)
            switch (tipName) {
                case 'click':
                    this.currentTip = this.currentTip === null ? null : 'zoom'
                    break
                case 'zoom':
                    this.currentTip = this.currentTip === null ? null : 'move'
                    break
                case 'move':
                    this.currentTip = null
            }
        },
    },
}
</script>

<style lang="scss">
.cic {
    position: relative;

    &__animation-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        pointer-events: none;
        margin-left: 10px;
        margin-top: 20px;
    }

    &__image-container {
        width: 100%;
        height: 100%;
    }

    &__loading {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
    }

    &__edit-button {
        z-index: 5;
        position: absolute !important;
        transform: scale(0);
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        transition: none;

        &--active {
            transform: scale(1);
            transition: transform 500ms ease;
        }
    }

    &__cancel-btn {
        position: absolute;
        bottom: 50%;
        left: 12px;
    }
    &__save-btn {
        position: absolute;
        bottom: 50%;
        right: 12px;
    }
}

.cic .cropper-container .cropper-crop-box {
    display: none;
}

.cic .cropper-container .cropper-modal {
    background: none;
    opacity: 1;
}
</style>
