<template>
    <div class="d-flex justify-center">
        <AnimationLoop
            v-if="!animationFinished"
            animation-name="love"
            :loop="false"
            :delay-millis="500"
            size="100%"
            :on-complete="onAnimationComplete"
        />
        <div class="order-success" v-else>
            <h1 class="order-success__title">
                Vielen Dank für Deine Bestellung!
            </h1>
            <p class="order-success__description">
                Wir haben deine Bestellung erhalten. Sobald wir deine Bestellung
                verarbeitet haben erhältst du von uns eine Bestätigungsmail, in
                der du auch die Rechnung findest.&#42; Wir starten so schnell
                wie möglich mit der Produktion. Sobald wir dein Wandbild
                produziert haben, erhältst du von uns eine weitere Email mit der
                Sendungsverfolgungsnummer
            </p>
            <h2 class="order-success__order-number">
                Bestellnummer: <strong>{{ this.$route.query.orderId }}</strong>
            </h2>
            <PrintPreview
                :custom-template="selectedProductType?.customTemplate"
                class="order-success__preview"
                :disabled="true"
                :framed="true"
            />
            <v-btn to="/" class="mt-16" color="success" x-large
                >Zurück zum Start</v-btn
            >
            <p class="order-success__notice">
                &#42; Bitte beachte, dass es einige Minuten dauern kann, bis
                dich die Mail mit den Bestelldetails erreicht. Sollte dich die
                Mail nicht erreichen kannst du uns eine Mail an du@malen.es
                schreiben. Bitte gib dabei deine Bestellnummer (z.B. #000001)
                und/oder den Namen unter dem du die Bestellung aufgegeben hast.
            </p>
        </div>
    </div>
</template>

<script>
import AnimationLoop from '@/components/AnimationLoop'
import ProductPreview from '@/components/OrderResult/ProductPreview'
import PrintPreview from '@/components/PrintGenerator/components/PrintPreview'
import { mapGetters } from 'vuex'

export default {
    name: 'OrderSuccess',
    components: { PrintPreview, ProductPreview, AnimationLoop },
    data() {
        return {
            animationFinished: false,
        }
    },
    computed: {
        ...mapGetters(['selectedProductType']),
    },
    methods: {
        onAnimationComplete() {
            this.animationFinished = true
        },
    },
}
</script>

<style lang="scss" scoped>
.order-success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 36px;
    max-width: 600px;

    &__title {
        text-align: center;
    }

    &__description {
        margin-top: 16px;
        text-align: center;
    }

    &__order-number-label {
        margin-top: 16px;
        font-weight: 400;
        color: #969696;
    }

    &__order-number {
        text-align: center;
    }

    &__preview {
        margin-top: 16px;
        width: 90%;
        max-width: 400px;
    }

    &__notice {
        margin-top: 48px;
        font-size: 12px;
        font-weight: 300;
        text-align: center;
    }
}
</style>
