<template>
    <v-card
        v-else
        class="credit-option-card"
        :class="{ 'credit-option-card--highlight': !!highlight }"
    >
        <v-card-text>
            <div class="card-container">
                <h1>{{ contingent }} ₱</h1>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn x-large width="100%" color="success">{{ price }} €</v-btn>
        </v-card-actions>
        <div class="highlight" style="border-radius: 17px" v-if="highlight">
            <v-avatar size="30px" color="red">
                <v-icon>mdi-fire</v-icon>
            </v-avatar>
            <div class="highlight__text">{{ highlight }}</div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'CreditOptionCard',
    props: {
        price: {
            type: String,
            required: true,
        },
        contingent: {
            type: String,
            required: true,
        },
        highlight: {
            type: String,
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.card-container {
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
        text-align: center;
        font-size: 65px;
    }
}

.credit-option-card {
    border: 2px solid rgba(0, 0, 0, 0);

    &--highlight {
        border: 2px solid #ff4b2b;
    }
}

.highlight {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fff;
    height: 34px;
    border: 2px solid #ff4b2b;
    display: flex;
    align-items: center;

    &__text {
        margin-left: 12px;
        margin-right: 12px;
        font-size: 16px;
        line-height: 16px;
        color: #ff4b2b;
        font-weight: 600;
    }
}
</style>
