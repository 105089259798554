<template>
    <v-container style="overflow: visible">
        <MainHeader class="main-header" />
        <router-view />
    </v-container>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import OrderResults from '@/components/OrderResult/OrderResults'
export default {
    name: 'OrderView',
    components: { OrderResults, MainHeader },
}
</script>

<style scoped></style>
