<template>
    <v-container class="d-flex justify-center flex-column align-center">
        <AnimatedLogo class="ma-6" /> 1.Use of Service: This section should
        outline the terms and conditions for using your service, such as who is
        eligible to use the service, the purpose of the service, and any
        prohibited uses. For example, you may want to specify that users must be
        18 years or older to use the service, and that the service is intended
        for personal and non-commercial use only.
        <br />
        2. User Content: This section should describe the rights and
        responsibilities of users who upload content to your website, such as
        images. For example, you may want to specify that users are solely
        responsible for the content they upload, and that they grant your
        website a license to use, reproduce, and display the content for the
        purpose of providing the service.
        <br />
        3. Processing of Images: This section should describe the process of how
        the images are processed and stored on your server. For example, you may
        want to specify that the images will not be shared with any third-party
        services or individuals, and that you will take appropriate measures to
        ensure the security and confidentiality of the images.
        <br />
        4. Intellectual Property: This section should describe the ownership and
        use of intellectual property related to your service, such as
        trademarks, copyrights, and patents. For example, you may want to
        specify that your website and its contents are protected by copyright
        and trademark laws, and that users may not use or reproduce any of the
        content without prior written permission.
        <br />
        5. Limitation of Liability: This section should outline the limits of
        your liability for any damages or losses that may result from the use of
        your service. For example, you may want to specify that your website is
        not responsible for any errors, omissions, or inaccuracies in the
        content, and that users assume all risks associated with the use of the
        service.
        <br />
        6. Termination: This section should describe the conditions under which
        you may terminate a user's access to your service. For example, you may
        want to specify that you reserve the right to terminate a user's account
        for any reason, including violation of the terms and conditions.
        <br />
        7. Governing Law: This section should specify the governing law and
        jurisdiction that will apply to any disputes or claims that may arise
        from the use of your service. For example, you may want to specify that
        the laws of your state or country will apply, and that any disputes will
        be resolved through binding arbitration or in a court of law.
    </v-container>
</template>

<script>
import AnimatedLogo from '@/components/AnimatedLogo'
export default {
    name: 'TermsAndConditions',
    components: { AnimatedLogo },
}
</script>

<style scoped></style>
