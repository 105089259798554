<template>
    <div class="quantity-selector">
        <v-btn><v-icon>mdi-plus</v-icon></v-btn>
        <v-text-field readonly :value="count"></v-text-field>
        <v-btn><v-icon>mdi-minus</v-icon></v-btn>
    </div>
</template>

<script>
export default {
    name: 'QuantitySelector',
    data() {
        return {
            count: 1,
        }
    },
    params: {
        quantity: {
            default: 1,
        },
    },
    created() {
        this.count = this.quantity
    },
    methods: {
        changeCounter(val) {
            this.count = this.count + val
            this.$emit('change', this.count)
        },
    },
}
</script>

<style scoped></style>
