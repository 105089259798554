<template>
    <MainDialog
        width="400"
        :persistent="false"
        :show="show"
        :on-close="onClose"
    >
        <v-card v-if="!sentEmail">
            <v-card-title>Schreibe uns eine Nachricht</v-card-title>
            <v-form ref="entryForm" @submit.prevent="submitHandler">
                <v-card-text>
                    <v-text-field v-model="name" outlined label="Dein Name" />
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        outlined
                        label="Deine Email"
                    />
                    <v-text-field
                        v-model="subject"
                        :rules="subjectRules"
                        outlined
                        label="Dein Betreff"
                    />
                    <v-textarea
                        v-model="message"
                        :rules="messageRules"
                        outlined
                        label="Deine Nachricht"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="isSending" text @click="onClose"
                        >Abbrechen</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="isSending"
                        :loading="isSending"
                        color="success"
                        type="submit"
                        >Email Senden</v-btn
                    >
                </v-card-actions>
            </v-form>
        </v-card>
        <div v-else class="success-animation pa-6 pb-12 pt-12 text-center">
            <AnimationLoop
                animation-name="sent"
                size="300px"
                height="300px"
                :loop="false"
            />
            Wir haben deine Email erhalten und versuchen schnellstmöglich dein
            Anliegen zu bearbeiten.
            <v-btn class="mt-6" text @click="onClose">Schließen</v-btn>
        </div>
    </MainDialog>
</template>

<script>
import MainDialog from '@/components/MainDialog'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import AnimationLoop from '@/components/AnimationLoop'
export default {
    name: 'ContactDialog',
    components: { AnimationLoop, MainDialog },
    data() {
        return {
            isSending: false,
            sentEmail: false,
            name: '',
            email: '',
            subject: '',
            message: '',
            emailRules: [],
            subjectRules: [],
            messageRules: [],
        }
    },
    props: {
        show: {
            required: true,
        },
        onClose: {
            required: false,
        },
    },
    methods: {
        submitHandler() {
            console.log('submit')
            this.emailRules = [
                (v) =>
                    !!v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Email Adresse ungültig.',
            ]
            this.subjectRules = [(v) => !!v || 'Bitte gib einen Betreff an.']
            this.messageRules = [(v) => !!v || 'Deine Nachricht ist zu kurz.']
            let self = this
            setTimeout(function () {
                if (self.$refs.entryForm.validate()) {
                    //other codes
                    self.sendMail()
                }
            }, 200)
        },
        async sendMail() {
            this.isSending = true
            const sendContactMail = httpsCallable(functions, 'sendContactMail')

            await sendContactMail({
                name: this.name,
                email: this.email,
                subject: this.subject,
                message: this.message,
            })
            this.isSending = false
            this.sentEmail = true
        },
    },
}
</script>

<style lang="scss" scoped>
.success-animation {
    background: #ffffff;
}
</style>
