const defaultState = {
    currentDrawing: {
        uuid: '',
        resultImage: '',
        resultImageW: '',
        originalImage: '',
        cutoutMask: '',
    },
    customization: {
        templatePath: ['print', 'vertical'],
        backgroundColor: '#FFFFFFFF',
        text: 'Liebling',
        textColor: '#000000FF',
        textSize: 15,
        textFont: 'Amatic SC',
        croppedImage: null,
        cropperValues: null,
        useCroppedImage: false,
        clippedImage: null,
        useClippedImage: false,
        expansionPanel: 0,
        effectStrength: 1,
    },
}

const getters = {
    currentDrawing: (state) => state.currentDrawing,
    customization: (state) => state.customization,
    resultImage: (state) => state.resultImage,
    templatePath: (state) => state.customization.templatePath,
    effectStrength: (state) => state.customization.effectStrength,
}

const actions = {
    saveClippedImage({ state, commit }, image) {
        commit('setClippedImage', image)
    },
    async resetCustomization({ commit }) {
        await commit('setCustomization', {
            ...defaultState.customization,
        })
    },
}

const mutations = {
    setCurrentDrawing: (state, drawingObject) => {
        state.currentDrawing = { ...drawingObject }
    },
    setCustomization: (state, customization) =>
        (state.customization = customization),
    setCroppedImage: (state, cropData) => {
        if (cropData) {
            state.customization.croppedImage = cropData.dataUrl
            state.customization.cropperValues = cropData.values
            state.customization.useCroppedImage = true
        } else {
            state.customization.croppedImage = null
            state.customization.cropperValues = null
            state.customization.useCroppedImage = false
        }
    },
    setTemplatePath: (state, path) => (state.customization.templatePath = path),
    setEffectStrength: (state, strength) => {
        state.customization.effectStrength = strength
    },
    setClippedImage: (state, image) =>
        (state.customization.clippedImage = image),
}

const state = window.sessionStorage[process.env.VUE_APP_NAME]
    ? JSON.parse(window.sessionStorage[process.env.VUE_APP_NAME]).generator
    : Object.assign({}, defaultState)

export default {
    state,
    getters,
    actions,
    mutations,
}
