<template>
    <v-expansion-panels multiple accordion>
        <v-expansion-panel v-for="(block, i) in informationBlocks" :key="i">
            <v-expansion-panel-header>{{
                block.title
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-html="block.content"></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'ProductInformationBlocks',
    props: {
        informationBlocks: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style scoped></style>
