import { httpsCallable } from 'firebase/functions'
import { db, functions } from '../../../firebase'
import { doc, onSnapshot } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

let unsubAppData

const defaultState = {
    myDrawings: [],
    isGenerating: false,
    showTips: true,
    key: '',
    contingent: 0,
    currentUser: null,
}

const getters = {
    myDrawings: (state) => state.myDrawings,
    key: (state) => state.key,
    contingent: (state) => state.contingent,
    showTips: (state) => state.showTips,
    currentUser: (state) => state.currentUser,
}

const actions = {
    async getDrawing({ getters }, drawingUuid) {
        return getters.myDrawings.find(
            (drawing) => drawing.uuid === drawingUuid
        )
    },
    async checkKey({ commit }, key) {
        if (key) {
            const checkIn = httpsCallable(functions, 'checkIn')
            const res = await checkIn({
                key,
            })
            if (res.data.success) {
                commit('setKey', key)
                commit('setContingent', res.data.data.contingent)
            }
        }
    },
    async unsubscribeAppData() {
        unsubAppData()
    },
    async listenForUserChanges({ commit }) {
        const auth = getAuth()
        console.log('hi')
        unsubAppData = onSnapshot(
            doc(db, 'users', auth.currentUser.uid),
            (doc) => {
                const data = {
                    ...doc.data(),
                    drawings: doc.data().drawings.map((drawing) => {
                        return {
                            uuid: drawing.uuid,
                            resultImage: drawing.result_image,
                            resultImageW: drawing.result_image_c,
                            resultImageClipped: drawing.result_image_c,
                            originalImage: drawing.original_image,
                        }
                    }),
                }
                console.log(data)
                commit('setAppData', { ...data, currentUser: auth.currentUser })
            }
        )
    },
}

const mutations = {
    addDrawing: (state, drawing) => state.myDrawings.push(drawing),
    setShowTips: (state, bool) => (state.showTips = bool),
    setIsGenerating: (state, bool) => (state.isGenerating = bool),
    updateDrawing: (state, drawing) => {
        const index = state.myDrawings.findIndex(
            (existingDrawing) => existingDrawing.uuid === drawing.uuid
        )
        Object.assign(state.myDrawings[index], { ...drawing })
    },
    setKey: (state, key) => (state.key = key),
    setContingent: (state, contingent) => (state.contingent = contingent),
    decreaseContingent: (state) => (state.contingent = state.contingent - 1),
    setAppData: (state, appData) => {
        {
            if (appData) {
                state.myDrawings = appData.drawings
                state.contingent = appData.contingent
                state.currentUser = appData.currentUser
            } else {
                state.myDrawings = []
                state.contingent = 0
                state.currentUser = null
            }
        }
    },
}

const state = window.sessionStorage[process.env.VUE_APP_NAME]
    ? JSON.parse(window.sessionStorage[process.env.VUE_APP_NAME]).app
    : Object.assign({}, defaultState)

export default {
    state,
    getters,
    actions,
    mutations,
}
