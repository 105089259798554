<template>
    <div v-resize="positionImage" class="mockup-image-wrapper">
        <v-img
            @load="positionImage"
            ref="mockupImg"
            class="mockup-image"
            :src="mockupImgSrc"
        />
        <div
            v-if="matrix3d"
            :style="`transform: ${matrix3d}`"
            class="mockup-content"
        >
            <div class="slot-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import numeric from 'numeric'

export default {
    name: 'MockupImageWrapper',
    props: {
        mockupImgSrc: {
            type: String,
            required: true,
        },
        productImageCorners: {
            type: Array, // [topLeft, topRight, bottomRight, bottomLeft] -> topLeft = [relativX (from left), relativeY (from top)]
        },
        mockupImgSrcLazy: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            matrix3d: null,
        }
    },
    mounted() {
        this.positionImage()
    },
    methods: {
        positionImage() {
            const mockupWidth = this.$refs.mockupImg.$el.offsetWidth
            const mockupHeight = this.$refs.mockupImg.$el.offsetHeight

            const absoluteCornersArray = this.translateRelativePositions(
                this.productImageCorners,
                mockupWidth,
                mockupHeight
            )

            const sourcePoints = this.getSourcePoints(mockupWidth, mockupHeight)

            this.matrix3d = this.computeTransform(
                sourcePoints,
                absoluteCornersArray
            )
        },
        computeTransform(sourcePoints, absoluteCornersArray) {
            // sourcePoints and absoluteCornersArray should have length 4 each
            const count = 4
            const a = [] // (2*count) x 8 matrix
            const b = [] // (2*count) vector

            for (let i = 0; i < 2 * count; ++i) {
                a.push([0, 0, 0, 0, 0, 0, 0, 0])
                b.push(0)
            }

            for (let i = 0; i < count; ++i) {
                let j = i + count
                a[i][0] = a[j][3] = sourcePoints[i][0]
                a[i][1] = a[j][4] = sourcePoints[i][1]
                a[i][2] = a[j][5] = 1
                a[i][3] = a[i][4] = a[i][5] = a[j][0] = a[j][1] = a[j][2] = 0
                a[i][6] = -sourcePoints[i][0] * absoluteCornersArray[i][0]
                a[i][7] = -sourcePoints[i][1] * absoluteCornersArray[i][0]
                a[j][6] = -sourcePoints[i][0] * absoluteCornersArray[i][1]
                a[j][7] = -sourcePoints[i][1] * absoluteCornersArray[i][1]
                b[i] = absoluteCornersArray[i][0]
                b[j] = absoluteCornersArray[i][1]
            }

            const x = numeric.solve(a, b)
            // matrix3d is homogenous coords in column major!
            // the z coordinate is unused
            const m = [
                x[0],
                x[3],
                0,
                x[6],
                x[1],
                x[4],
                0,
                x[7],
                0,
                0,
                1,
                0,
                x[2],
                x[5],
                0,
                1,
            ]
            let transform = 'matrix3d('
            for (let i = 0; i < m.length - 1; ++i) {
                transform += m[i] + ','
            }
            transform += m[15] + ')'
            return transform
        },
        translateRelativePositions(relativeCornersArray, width, height) {
            const absoluteCornersArray = []
            relativeCornersArray.forEach((point) => {
                const absolutPoint = [point[0] * width, point[1] * height]
                absoluteCornersArray.push(absolutPoint)
            })
            return absoluteCornersArray
        },
        getSourcePoints(width, height) {
            return [
                [0, 0],
                [width, 0],
                [width, height],
                [0, height],
            ]
        },
    },
}
</script>

<style scoped>
.slot-content {
    width: 100%;
    height: 100%;
    position: relative;
}
.mockup-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: -1;
    /*mix-blend-mode: multiply;*/
    mix-blend-mode: multiply;
    pointer-events: none;
}

.mockup-content {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 0 0;
    mix-blend-mode: multiply;
    /*transform-style: preserve-3d;*/
    pointer-events: auto;
}
</style>
