<template>
    <div>
        <div
            @click="onSelect"
            :class="[
                { 'textImageCard--active': selected },
                { 'textImageCard--with-image': image },
            ]"
            class="textImageCard"
        >
            <v-img class="textImageCard__img" :src="image" :lazy-src="lazy" />
            <div class="textImageCard__text">
                {{ description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateCard',
    props: {
        image: {
            required: true,
        },
        description: {
            type: String,
            require: false,
        },
        onSelect: {
            type: Function,
        },
        selected: {
            type: Boolean,
        },
    },
}
</script>

<style lang="scss" scoped>
.textImageCard {
    transition: all 0.125s;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;

    &--active {
        transform: scale(1.025);
        transition: all 0.125s;
        box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px !important;
    }

    &--with-image {
        box-shadow: none;
    }

    &__background-image {
        height: 100%;
        width: 100%;
        position: absolute;
    }

    &__text {
        text-align: center;
        display: block;
        line-height: 16px;
        font-size: 16px;
        padding: 12px;
        z-index: 2;

        @media only screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    &__img {
        border-radius: 5px;
    }
}
</style>
