<template>
    <v-container>
        <MainHeader class="main-header" />
        <DrawingResult :drawing-uuid="$route.params.id" />
    </v-container>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import DrawingResult from '@/components/DrawingResult'

export default {
    name: 'ResultView',
    components: {
        DrawingResult,
        MainHeader,
    },
}
</script>

<style lang="scss" scoped>
.main-header {
    margin-top: 30px;
    margin-bottom: 60px;
}
</style>
