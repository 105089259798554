<template>
    <v-container>
        <div class="login-container">
            <Login />
        </div>
    </v-container>
</template>

<script>
import CreateAccount from '@/components/Authentication/CreateAccount/CreateAccount'
import FinalizeAccountCreation from '@/components/Authentication/CreateAccount/FinalizeAccountCreation'
import Login from '@/components/Authentication/Login'
export default {
    name: 'LoginView',
    components: { Login, FinalizeAccountCreation, CreateAccount },
    data() {
        return {
            registrationKey: this.$route.params.registrationKey,
        }
    },
}
</script>

<style lang="scss" scoped>
.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
