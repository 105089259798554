<template>
    <v-btn
        :disabled="disabled"
        width="100%"
        :color="color"
        x-large
        @click="onClickHandler"
        loading-text="Template wird erstellt"
        class="progress-button"
    >
        <template v-if="!loading">
            <v-img
                v-if="labelImage"
                width="auto"
                height="24px"
                contain
                :src="labelImage"
            />
            <div :class="{ 'ml-3': !!labelImage }" v-if="label">
                {{ label }}
            </div>
        </template>
        <div v-else>
            {{ loadingText }}
        </div>
        <v-progress-linear
            class="progress-button__progress-bar"
            v-if="loading"
            :indeterminate="!progress"
            :value="progress"
        />
    </v-btn>
</template>

<script>
export default {
    name: 'ProgressButton',
    props: {
        onClickHandler: {
            type: Function,
        },
        color: {
            default: 'success',
        },
        label: {
            type: String,
        },
        labelImage: {
            type: String,
        },
        loadingText: {
            type: String,
        },
        progress: {
            type: Number,
            required: false,
        },
        loading: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
    },
}
</script>

<style lang="scss" scoped>
.progress-button {
    overflow: hidden;

    ::v-deep .v-btn__content {
        position: unset;
    }

    &__progress-bar {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
</style>
