<template>
    <div @click="$router.push('/')" class="logo-container">
        <div class="inner-container">
            <h1><span>malen.es</span></h1>
            <div class="blobs_1"></div>
            <div class="blobs_2"></div>
            <div class="blobs_3"></div>
            <div class="blobs_4"></div>
            <div class="blobs_5"></div>
            <div class="blobs_6"></div>
            <div class="blobs_7"></div>
            <div class="blobs_8"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnimatedLogo',
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Titan+One');
.logo-container {
    width: 200px;
    height: 200px;
    background-position: center;
    background-size: contain;
    overflow: hidden;
    cursor: pointer;
}

.inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

h1 {
    background: #fff;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    padding: 0;
    display: flex;
    font-family: 'Titan One', cursive;
    font-size: 39px;
    line-height: 39px;
    font-weight: 700;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    user-select: none;
}

h1 span {
    width: 100%;
    position: relative;
}

h1 span:before {
    background: linear-gradient(45deg, #fc5c7d, #163ef6, #fc5c7d);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    content: '';
    mix-blend-mode: screen;
}

[class*='blobs'] {
    position: absolute;
    mix-blend-mode: color;
    animation: blobs 15s ease-in-out infinite alternate;
}

.blobs_1 {
    background: #fc5ab3;
    width: 30px;
    height: 40px;
    top: 37%;
    left: 4%;
}

.blobs_2 {
    background: #ff4500;
    width: 90px;
    height: 80px;
    top: 30%;
    left: 20%;
}

.blobs_3 {
    background: #00ff00;
    width: 180px;
    height: 120px;
    top: calc(1% - 50px);
    left: 60%;
}

.blobs_4 {
    background: #530ac9;
    width: 130px;
    height: 100px;
    top: 70%;
    left: 100px;
}

.blobs_5 {
    background: #ffff00;
    width: 40%;
    height: 40%;
    top: 40%;
    left: 40%;
}

.blobs_6 {
    background: #00ffff;
    width: 100px;
    height: 90px;
    top: calc(1% - 30px);
    left: calc(1% - 30px);
}

.blobs_7 {
    background: #ff8c00;
    width: 40%;
    height: 23%;
    top: 80%;
    left: 12%;
}
.blobs_8 {
    background: #34c0eb;
    width: 70px;
    height: 50px;
    top: 130px;
    left: 360px;
}

@keyframes blobs {
    0% {
        border-radius: 26% 74% 61% 39% / 54% 67% 33% 46%;
    }
    10% {
        border-radius: 74% 26% 47% 53% / 68% 46% 54% 32%;
    }
    20% {
        border-radius: 48% 52% 30% 70% / 27% 37% 63% 73%;
    }
    30% {
        border-radius: 73% 27% 57% 43% / 28% 67% 33% 72%;
    }
    40% {
        border-radius: 63% 37% 56% 44% / 25% 28% 72% 75%;
    }
    50% {
        border-radius: 39% 61% 70% 30% / 61% 29% 71% 39%;
    }
    60% {
        border-radius: 27% 73% 29% 71% / 73% 51% 49% 27%;
    }
    70% {
        border-radius: 39% 61% 65% 35% / 74% 65% 35% 26%;
    }
    80% {
        border-radius: 55% 45% 37% 63% / 38% 30% 70% 62%;
    }
    90% {
        border-radius: 25% 75% 70% 30% / 39% 50% 50% 61%;
    }
    100% {
        border-radius: 66% 34% 33% 67% / 65% 73% 27% 35%;
    }
}
</style>
