const defaultState = {
    selectedProductType: null,
    totalPrice: 0.0,
    selectedSku: null,
    quantity: 1, //TODO Implement Quantity
}

const getters = {
    selectedProductType: (state) => state.selectedProductType,
    totalPrice: (state) => state.totalPrice,
    selectedSku: (state) => state.selectedSku,
    quantity: (state) => state.quantity,
}

const actions = {}

const mutations = {
    setSelectedProductType: (state, selectedProductType) => {
        state.selectedProductType = selectedProductType
        state.totalPrice = selectedProductType.variants?.[0].price || 0.0
        state.selectedSku =
            selectedProductType.variants?.[0].sku || selectedProductType.sku
    },
    setTotalPrice: (state, totalPrice) => (state.totalPrice = totalPrice),
    setSelectedSku: (state, selectedSku) => (state.selectedSku = selectedSku),
    setQuantity: (state, quantity) => (state.quantity = quantity),
}

const state = window.sessionStorage[process.env.VUE_APP_NAME]
    ? JSON.parse(window.sessionStorage[process.env.VUE_APP_NAME]).order
    : Object.assign({}, defaultState)

export default {
    state,
    getters,
    actions,
    mutations,
}
