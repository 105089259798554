<template>
    <div class="email-sent-container">
        <h1>Danke {{ name }}!</h1>
        <AnimationLoop
            animation-name="sent"
            size="300px"
            height="300px"
            :loop="false"
        />
        <p>
            Wir haben einen Link an <b>{{ email }}</b> gesendet. Bitte öffne die
            Mail und klicke auf den Link und bestätige deine Email. Nach
            erfolgreicher Bestätigung, kannst du dich auf unserer Seite
            anmelden.
        </p>
    </div>
</template>

<script>
import AnimationLoop from '@/components/AnimationLoop'
export default {
    name: 'EmailSent',
    components: { AnimationLoop },
    props: {
        email: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.email-sent-container {
    width: 400px;
    max-width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
</style>
