<template>
    <v-footer class="main-footer" padless>
        <v-card width="100%" flat tile class="white--text text-center">
            <v-card-text class="main-footer__bottom">
                {{ new Date().getFullYear() }} Malen.es — <a>Impressum</a> —
                <a>Datenschutz</a> —
                <a @click="openContact">Kontakt</a>
            </v-card-text>
        </v-card>
        <ContactDialog
            :show="showContactDialog"
            :on-close="
                () => {
                    showContactDialog = false
                }
            "
        />
    </v-footer>
</template>

<script>
import ContactDialog from '@/components/ContactDialog'
export default {
    name: 'MainFooter',
    components: { ContactDialog },
    data: () => ({
        icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
        expand: false,
        showContactDialog: false,
    }),
    methods: {
        openContact() {
            console.log('open')
            this.showContactDialog = true
        },
    },
}
</script>

<style lang="scss" scoped>
.main-footer {
    background-color: #bbbbbb;
    margin-top: 36px;
    width: 100%;
    justify-content: center;
    z-index: 0;

    a {
        color: #696969;
        font-weight: bold;
    }
}
</style>
