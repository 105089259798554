var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.computeTextSize),expression:"computeTextSize"}],ref:"printPreview",staticClass:"printPreview"},[(!_vm.customization.useClippedImage)?_c('CroppableImageConatiner',{key:_vm.customization.useClippedImage,staticClass:"printPreview__drawing",style:(`
                bottom: ${_vm.templateData.drawingContainer.bottom}%;
                width: ${_vm.templateData.drawingContainer.width}%;
                height: ${_vm.templateData.drawingContainer.height}%;`),attrs:{"disabled":_vm.disabled,"image":_vm.customization.croppedImage ||
            _vm.currentDrawing?.resultImageCompressed,"original-image":_vm.currentDrawing?.resultImageCompressed,"view-mode":3},on:{"onCrop":_vm.cropImage,"onCropReset":_vm.cropImage}}):(
            _vm.customization.useClippedImage && _vm.customization.clippedImage
        )?_c('CroppableImageConatiner',{key:_vm.customization.useClippedImage,staticClass:"printPreview__drawing",style:(`
                bottom: ${_vm.templateData.drawingContainer.bottom}%;
                width: ${_vm.templateData.drawingContainer.width}%;
                height: ${_vm.templateData.drawingContainer.height}%;
                background: ${_vm.customization.backgroundColor};`),attrs:{"disabled":_vm.disabled,"image":_vm.customization.croppedImage || _vm.customization.clippedImage,"original-image":_vm.customization.clippedImage,"view-mode":0},on:{"onCrop":_vm.cropImage,"onCropReset":_vm.cropImage}}):_c('div',{staticClass:"printPreview__cutout-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"30"}}),_c('div',{staticClass:"printPreview__loading-text"},[_vm._v(" Foto wird ausgeschnitten... ")])],1),_c('div',{staticClass:"printPreview__template",style:(`
                padding-top: ${
                    _vm.useAspectRatio ? _vm.templateData.aspectRatio : 100
                }%;
                background-color: ${
                    _vm.customization.useClippedImage
                        ? '#fff'
                        : _vm.customization.backgroundColor
                };
            `)}),(_vm.templateData.templateFile || _vm.customTemplate)?_c('img',{key:_vm.templateData.templateFile,staticClass:"printPreview__template-file",attrs:{"src":_vm.customTemplate?.templateFileLazy ||
            _vm.templateData.templateFileLazy,"height":"100%","width":"100%"}}):_vm._e(),_c('div',{key:_vm.customization.textSize + _vm.customization.textFont,staticClass:"printPreview__textContainer",style:(`bottom: ${_vm.templateData.text.bottom}%`)},[_c('div',[_c('div',{staticClass:"printPreview__text",style:(`
                                color: ${_vm.customization.textColor};
                                font-size: ${_vm.computedTextSize}px;
                                font-family: '${_vm.customization.textFont}';
                            `)},[_vm._v(" "+_vm._s(_vm.customization.text)+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }