<template>
    <div class="logo-container">
        <v-img
            class="logo"
            max-width="50%"
            @click="$router.push('/')"
            :lazy-src="require('@/assets/images/logo_lazy.png')"
            :src="require('@/assets/images/logo.png')"
        />
    </div>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth'
import MainMenu from '@/components/MainMenu'

export default {
    name: 'MainHeader',
    components: { MainMenu },
    data() {
        return {
            showNav: true,
        }
    },
    methods: {
        logOut() {
            const auth = getAuth()
            console.log(auth)
            signOut(auth)
            this.$router.replace('/login')
        },
    },
}
</script>

<style lang="scss" scoped>
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 36px;
}
.logo {
    cursor: pointer;
}
</style>
