<template>
    <MainDialog
        width="800"
        :persistent="false"
        :show="show"
        :on-close="onClose"
    >
        <v-card>
            <v-card-title>{{ $t('dialog.createAccount.title') }}</v-card-title>
            <v-card-text>
                <AnimationLoop
                    height="300px"
                    size="300px"
                    animation-name="locked"
                />
                <h3>{{ $t('dialog.createAccount.header') }}</h3>
                <p
                    class="pt-3"
                    v-html="$t('dialog.createAccount.description')"
                ></p>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-for="pack in packages"
                        :key="pack.price"
                        ><BuyCreditCard
                            :price="pack.price"
                            :contingent="pack.contingent"
                            :highlight="pack.highlight"
                    /></v-col>
                </v-row>
                <div class="credit-info-text">1 ₱ = 1 Portrait</div>
            </v-card-text>
        </v-card>
    </MainDialog>
</template>

<script>
import MainDialog from '@/components/MainDialog'
import AnimationLoop from '@/components/AnimationLoop'
import { mapGetters } from 'vuex'
import BuyCreditCard from '@/components/BuyCreditsDialog/components/CreditOptionCard'
export default {
    name: 'BuyCreditsDialog',
    components: { BuyCreditCard, AnimationLoop, MainDialog },
    data() {
        return {
            packages: [
                {
                    price: 2,
                    contingent: 1,
                },
                {
                    price: 4,
                    contingent: 5,
                    highlight: 'Am Beliebtesten',
                },
                {
                    price: 10,
                    contingent: 20,
                },
            ],
        }
    },
    props: {
        show: {
            default: false,
        },
        onClose: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['key']),
    },
    methods: {
        createAccount() {
            window.open(
                `${process.env.VUE_APP_MAIN_URL}/register/${this.key}`,
                '_self'
            )
        },
    },
}
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
    padding-left: 0;
}
li {
    position: relative;
    padding-left: 1.75em; /* space to preserve indentation on wrap */
}
li:before {
    content: ''; /* placeholder for the SVG */
    position: absolute;
    left: 0; /* place the SVG at the start of the padding */
    width: 1.5em;
    height: 1.5em;
    background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
        no-repeat;
}
.credit-info-text {
    padding: 12px;
    text-align: right;
}
</style>
