import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import app from '@/store/modules/app'
import generator from '@/store/modules/customizer'
import order from '@/store/modules/order'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
    key: process.env.VUE_APP_NAME,
    storage: window.localStorage,
})

export default new Vuex.Store({
    modules: {
        app,
        generator,
        order,
    },
    plugins: [vuexPersist.plugin],
})
