import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import LottieAnimation from 'lottie-web-vue'

import { ObserveVisibility } from 'vue-observe-visibility'
import i18n from './i18n'

Vue.directive('observe-visibility', ObserveVisibility) // TODO

Vue.use(LottieAnimation) // add lottie-animation to your global scope

Vue.config.productionTip = false

Vue.directive('resize', {
    inserted: function (el, binding) {
        const onResizeCallback = binding.value
        window.addEventListener('resize', () => {
            const width = document.documentElement.clientWidth
            const height = document.documentElement.clientHeight
            onResizeCallback({ width, height })
        })
    },
})

const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
