<template>
    <div>
        <v-btn
            class="main-menu-btn"
            color="pink"
            dark
            @click.stop="drawer = !drawer"
        >
            <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-navigation-drawer
            class="main-menu-navigation-drawer"
            height="100%"
            right
            v-model="drawer"
            absolute
            temporary
            v-if="drawer"
        >
            <v-list-item>
                <v-list-item-avatar>
                    <v-img
                        src="https://randomuser.me/api/portraits/men/78.jpg"
                    ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>John Leider</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item v-for="item in items" :key="item.title" link>
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'MainMenu',
    data() {
        return {
            drawer: null,
            items: [
                { title: 'Home', icon: 'mdi-view-dashboard' },
                { title: 'About', icon: 'mdi-forum' },
            ],
        }
    },
    watch: {
        show() {
            this.drawer = this.show
        },
    },
}
</script>

<style lang="scss" scoped>
.main-menu-btn {
    position: fixed;
    top: 42px;
    right: 42px;
    z-index: 10;

    @media only screen and (max-width: 600px) {
        top: 12px;
        right: 12px;
    }
}
.main-menu-navigation-drawer {
    z-index: 12;
    position: fixed;
}
</style>
