<template>
    <div
        v-if="show"
        :style="`height: 80vh; max-height: ${height}`"
        class="animation-container"
    >
        <LottieAnimation
            ref="anim"
            :animationData="require(`../assets/animations/${animationName}`)"
            :loop="loop"
            :autoPlay="autoPlay"
            :speed="1"
            :style="`width: 80%; height: 80%; max-width: ${size}; max-height: ${size};`"
            @complete="onComplete"
        />
        <div v-if="description" class="animation-description">
            <div v-html="description" />
        </div>
    </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
export default {
    name: 'AnimationLoop',
    components: { LottieAnimation },
    data() {
        return {
            show: false,
        }
    },
    props: {
        animationName: {
            type: String,
            required: true,
        },
        size: {
            default: '200px',
        },
        height: {
            default: '100%',
        },
        description: {
            type: String,
            required: false,
        },
        delayMillis: {
            type: Number,
        },
        autoPlay: {
            default: true,
        },
        loop: {
            default: true,
        },
        start: {
            required: false,
        },
        onComplete: {
            type: Function,
            default: () => 1,
        },
    },
    created() {
        if (this.delayMillis) {
            setTimeout(() => {
                this.show = true
            }, this.delayMillis)
        } else {
            this.show = true
        }
    },
}
</script>

<style lang="scss" scoped>
.animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.animation-description {
    text-align: center;
    margin: 0 30px;
}
</style>
