<template>
    <div class="card-container">
        <v-img
            class="mb-12"
            max-width="60%"
            :lazy-src="require('@/assets/images/logo_lazy.png')"
            :src="require('@/assets/images/logo.png')"
        />
        <v-form
            ref="entryForm"
            class="pl-3 pr-3"
            @submit.prevent="submitHandler"
            v-if="!loading && !error"
        >
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                disabled
                v-model="name"
                outlined
                label="Dein Vorname"
            />
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                v-model="email"
                outlined
                disabled
                label="Deine Email"
            />
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                type="password"
                :disabled="isProcessingRegistration"
                :rules="passwordRules"
                outlined
                v-model="password"
                label="Dein Passwort"
            />
            <v-checkbox
                style="margin-top: -12px"
                :rules="termsRules"
                dense
                v-model="isAcceptingTerms"
            >
                <div slot="label">
                    Ich habe die
                    <a href="/public" target="_blank">Nutzungsbedingungen</a>
                    gelesen und bin mit ihnen Einverstanden.
                </div>
            </v-checkbox>
            <v-btn
                :loading="isProcessingRegistration"
                :disabled="isProcessingRegistration"
                type="submit"
                x-large
                width="100%"
                color="success"
                >Jetzt Registrieren</v-btn
            >
        </v-form>
        <StateDisplay
            :loading="loading"
            :error="error"
            :error-message="errorMessage"
        />
    </div>
</template>

<script>
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import EmailSent from '@/components/Authentication/CreateAccount/components/EmailSent'
import { db, functions } from '../../../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import StateDisplay from '@/components/StateDisplay'
import { httpsCallable } from 'firebase/functions'
import AnimatedLogo from '@/components/AnimatedLogo'
import { EventBus, events } from '@/eventbus'

export default {
    name: 'FinalizeAccountCreation',
    components: { AnimatedLogo, StateDisplay, EmailSent },
    data() {
        return {
            email: '',
            password: '',
            name: '',
            termsRules: [],
            passwordRules: [],
            isAcceptingTerms: false,
            emailSent: false,
            isProcessingRegistration: false,
            loading: true,
            error: false,
            errorMessage: '',
        }
    },
    props: {
        registrationKey: {
            type: String,
            required: true,
        },
    },
    created() {
        console.log('ddfgeg')
        const registrationDoc = doc(db, 'registrations', this.registrationKey)
        getDoc(registrationDoc).then(async (registration) => {
            if (!registration.exists()) {
                this.errorMessage =
                    'Leider können wir deine Registrierung nicht verarbeiten :( Bitte überprüfe ob du dein Link mit dem aus unserer Mail übereinstimmt'
                this.error = true
            } else {
                const registrationData = registration.data()
                this.email = registrationData.email
                this.name = registrationData.name
            }
            this.loading = false
        })
    },
    methods: {
        submitHandler() {
            this.termsRules = [(v) => !!v]
            this.passwordRules = [
                (v) => !!v || 'Bitte wähle ein Passwort',
                (v) =>
                    (v &&
                        v.length >= 8 &&
                        /\d/.test(v) &&
                        /[a-zA-Z]/.test(v)) ||
                    'Das Passwort muss midestens 8 Zeichen lang sein und mindestens eine Nummer und einen Buchstaben enthalten.',
            ]
            let self = this
            setTimeout(function () {
                if (self.$refs.entryForm.validate()) {
                    //other codes
                    self.register()
                }
            }, 200)
        },
        async register() {
            this.isProcessingRegistration = true
            const registerUser = httpsCallable(functions, 'registerUser')
            const res = await registerUser({
                registrationKey: this.registrationKey,
            })

            if (res.data.success) {
                const auth = getAuth()
                await signInWithCustomToken(auth, res.data.token).then(() => {
                    this.$router.replace('/')
                })
            } else {
                EventBus.$emit(events.SNACKBAR, {
                    message: this.$t(`app.error.code.${res.data.errorCode}`),
                    type: 'error',
                })
            }
            this.isProcessingRegistration = false
        },
    },
}
</script>

<style lang="scss" scoped>
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    max-width: 90vw;
}
.card-content {
    margin-top: 24px;
}
</style>
