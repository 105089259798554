<template>
    <div class="card-container">
        <v-img
            class="mb-12"
            max-width="60%"
            :lazy-src="require('@/assets/images/logo_lazy.png')"
            :src="require('@/assets/images/logo.png')"
        />
        <v-form
            v-if="!emailSent"
            ref="entryForm"
            class="register-form pl-3 pr-3"
            @submit.prevent="submitHandler"
        >
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                v-model="name"
                :rules="nameRules"
                outlined
                label="Dein Vorname"
            />
            <v-text-field
                background-color="rgba(256,256,256,0.4)"
                :rules="emailRules"
                v-model="email"
                type="email"
                outlined
                label="Deine Email"
            />
            <v-checkbox dense v-model="isAcceptingTerms" :rules="termsRules">
                <template v-slot:label>
                    <div>
                        Die
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a
                                    target="_blank"
                                    href="/terms"
                                    @click.stop
                                    v-on="on"
                                >
                                    Nutzungsbedingungen
                                </a>
                            </template>
                            Nutzungsbedingungen öffnen
                        </v-tooltip>
                        habe ich gelesen und bin mit ihnen Einverstanden.
                    </div>
                </template>
            </v-checkbox>
            <v-btn
                :loading="isProcessingRegistration"
                :disabled="isProcessingRegistration"
                type="submit"
                x-large
                width="100%"
                color="success"
                >Jetzt Registrieren</v-btn
            >
            <v-btn
                :disabled="isProcessingRegistration"
                class="mt-3"
                type="submit"
                x-large
                width="100%"
                text
                to="/login"
                >Login</v-btn
            >
        </v-form>
        <EmailSent :email="email" :name="name" v-else />
    </div>
</template>

<script>
import EmailSent from '@/components/Authentication/CreateAccount/components/EmailSent'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../../firebase'
import { EventBus, events } from '@/eventbus'
import AnimatedLogo from '@/components/AnimatedLogo'

export default {
    name: 'CreateAccount',
    components: { AnimatedLogo, EmailSent },
    data() {
        return {
            email: '',
            name: '',
            emailRules: [],
            termsRules: [],
            nameRules: [],
            isAcceptingTerms: false,
            emailSent: false,
            isProcessingRegistration: false,
        }
    },
    methods: {
        submitHandler() {
            this.emailRules = [
                (v) => !!v || 'Bitte gib deine Email Adresse an.',
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Bitte gib eine gültige Email Adresse an.',
            ]
            this.termsRules = [(v) => !!v]
            this.nameRules = [
                (v) =>
                    (!!v && v.length > 1 && v.length < 13) ||
                    'Bitte verrate uns deinen Namen (Max. 12 Buchstaben)',
            ]
            let self = this
            setTimeout(function () {
                if (self.$refs.entryForm.validate()) {
                    //other codes
                    self.register()
                }
            }, 200)
        },
        async register() {
            this.isProcessingRegistration = true
            const createRegistrationLink = httpsCallable(
                functions,
                'createRegistrationLink'
            )
            const res = await createRegistrationLink({
                email: this.email,
                key: this.$route.params.key || null,
                name: this.name,
            })
            if (res.data.success) {
                this.emailSent = true
            } else {
                EventBus.$emit(events.SNACKBAR, {
                    message: this.$t('app.error.code.' + res.data.errorCode),
                    type: 'error',
                })
            }
            this.isProcessingRegistration = false
        },
    },
}
</script>

<style lang="scss" scoped>
.register-form {
    width: 400px;
    max-width: 90%;
}
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    max-width: 90vw;
}
.card-content {
    margin-top: 24px;
}

.terms-cb {
    //width: 290px;
}

.v-application .error--text {
    background-color: rgba(256, 256, 256, 0.4);
}
</style>
