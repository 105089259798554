<template>
    <v-snackbar
        shaped
        :color="snackColor"
        v-model="showSnackbar"
        :timeout="10000"
    >
        <span v-html="snackMessage"></span>
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="showSnackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { EventBus } from '@/eventbus'

export default {
    name: 'AppSnackbars',
    data() {
        return {
            showSnackbar: null,
            snackColor: 'success',
            snackMessage: '',
        }
    },
    created() {
        EventBus.$on('SNACKBAR', ({ message, type }) => {
            this.snackMessage = message
            this.snackColor = type || 'success'
            this.showSnackbar = true
        })
    },
}
</script>

<style scoped></style>
